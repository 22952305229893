import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  CSSProperties
} from "react";

import { AnimatePresence } from "framer-motion";

import {
  useFloating,
  useInteractions,
  useClick,
  useHover,
  useDismiss,
  useRole,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
  hide,
  safePolygon
} from "@floating-ui/react";

import type { Placement, Strategy } from "@floating-ui/react";

import usePopoverModal from "util/hooks/usePopoverModal";
import { TooltipVariant } from "components/atoms/ReactTooltip";

import S from "./styles";

const ARROW_MAX_BOUNDARY_OFFSET = 20;
const ARROW_MIN_BOUNDARY_OFFSET = 10;

interface Props {
  children: ReactNode;
  content: ReactNode;
  maxWidth?: string;
  isOpenOverride?: boolean;
  onRequestClose?: () => void;
  trigger?: string;
  interactive?: boolean;
  alignment?: Placement;
  distance?: number;
  tooltipOffset?: number;
  tooltipOuterPadding?: number;
  borderRadius?: number;
  position?: Strategy;
  delay?: number;
  disabled?: boolean;
  hideArrow?: boolean;
  bubbleCloseEvents?: boolean;
  disableHideOnClip?: boolean;
  className?: string;
  style?: CSSProperties;
  enablePopoverModalHook?: boolean;
  variant?: TooltipVariant;
  arrowSize?: string;
  arrowColor?: string;
}

const Popover = ({
  children,
  content,
  maxWidth = "340px",
  isOpenOverride = undefined, // When using this prop, type must be boolean
  onRequestClose = undefined,
  trigger = "mouseenter",
  interactive = false,
  alignment = "top",
  distance = 10,
  tooltipOffset = 0,
  tooltipOuterPadding = 5,
  borderRadius = 12,
  position = "absolute",
  delay = 500,
  disabled = false,
  hideArrow = false,
  bubbleCloseEvents = true,
  disableHideOnClip,
  enablePopoverModalHook = false,
  className,
  variant = TooltipVariant.DEFAULT,
  style,
  arrowSize,
  arrowColor
}: Props) => {
  const usePopoverModalHook = usePopoverModal();
  const [open, setOpen] = useState<undefined | boolean>();
  const [adjustedArrowPositionY, setAdjustedArrowPositionY] = useState<
    undefined | number
  >();

  const arrowRef = useRef(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = (change?: boolean) => {
    // Call onRequestClose callback if not undefined
    if (!change && onRequestClose) {
      onRequestClose();
    }

    if (isOpenOverride === undefined) {
      setOpen(change);
    }
  };

  const {
    x,
    y,
    context,
    placement,
    refs,
    middlewareData: {
      arrow: { x: arrowX, y: arrowY } = {},
      hide: { referenceHidden: hideTooltip } = {}
    }
  } = useFloating({
    open: isOpenOverride !== undefined ? isOpenOverride : open,
    onOpenChange: toggleOpen,
    placement: alignment,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: distance, crossAxis: tooltipOffset }),
      flip({ fallbackAxisSideDirection: "start" }),
      shift({ padding: tooltipOuterPadding }),
      arrow({ element: arrowRef }),
      ...(usePopoverModalHook && enablePopoverModalHook ? [] : [hide()])
    ],
    strategy: position
  });

  const isTooltipHidden = useCallback(() => {
    return hideTooltip && !disableHideOnClip;
  }, [disableHideOnClip, hideTooltip]);

  useEffect(() => {
    if (isTooltipHidden()) {
      if (onRequestClose) {
        onRequestClose();
      } else {
        setOpen(false);
      }
    }
  }, [hideTooltip, isTooltipHidden, onRequestClose]);

  // Keep the arrow within the popover height when scrolling, so it doesn't place
  // above the top side of the popover, and it doesn't place below the bottom side
  // of the popover.
  useEffect(() => {
    if (contentRef.current) {
      if (arrowY && arrowY < ARROW_MIN_BOUNDARY_OFFSET) {
        setAdjustedArrowPositionY(ARROW_MIN_BOUNDARY_OFFSET);
      } else if (
        arrowY &&
        arrowY > contentRef.current.clientHeight - ARROW_MAX_BOUNDARY_OFFSET
      ) {
        setAdjustedArrowPositionY(
          contentRef.current.clientHeight - ARROW_MAX_BOUNDARY_OFFSET
        );
      } else {
        setAdjustedArrowPositionY(arrowY);
      }
    }
  }, [arrowY, contentRef]);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      enabled: trigger === "click" && isOpenOverride === undefined
    }),
    useHover(context, {
      enabled: trigger === "mouseenter" && isOpenOverride === undefined,
      handleClose: interactive ? safePolygon() : null,
      delay: { open: delay, close: 1 }
    }),
    // bubbles: false will prevent click events on nested elements
    // from causing the popover to dismiss e.g. clicking on a modal
    // overlay.

    useDismiss(context, {
      bubbles: bubbleCloseEvents,
      outsidePress: event => {
        if (!event || !event.target) return false;

        return (
          !(event.target as HTMLElement).closest(".regen-bar") &&
          !(event.target as HTMLElement).closest("#portal-modal")
        );
      }
    }),
    useRole(context, {
      // @ts-ignore TODO: 'popover' is apparently not an option allowed
      role: "popover"
    })
  ]);

  // We want the arrow to place opposite our preferred placement
  // i.e. if the popover places right, we want our arrow to place
  // on the left side of the popover pointing to the reference
  const staticSide =
    {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right"
    }[placement.split("-")[0]] ?? "top";

  const variants = useMemo(
    () => ({
      visible: { opacity: 1 },
      hidden: { opacity: 0 }
    }),
    []
  );

  const getPopoverComponent = useCallback(
    (left: null | number, top: null | number) => (
      <S.Popover
        ref={refs.setFloating}
        {...getFloatingProps()}
        // @ts-ignore
        position={position}
        borderRadius={borderRadius}
        style={{
          // Doesn't like when I pass this to linaria
          top: top ?? "",
          left: left ?? ""
        }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={isTooltipHidden() ? "hidden" : "visible"}
        variants={variants}
        transition={{
          type: "spring",
          damping: 20,
          stiffness: 300
        }}
      >
        <S.ContentContainer ref={contentRef} maxWidth={maxWidth}>
          {content}
        </S.ContentContainer>
        {!hideArrow ||
          (usePopoverModalHook && enablePopoverModalHook ? null : (
            <S.Arrow
              ref={arrowRef}
              left={arrowX != null ? `${arrowX + tooltipOffset}px` : ""}
              top={arrowY != null ? `${adjustedArrowPositionY}px` : ""}
              style={{
                [staticSide]: "-5px"
              }}
              isDark={variant === TooltipVariant.DARK}
              size={arrowSize}
              background={arrowColor}
            />
          ))}
      </S.Popover>
    ),
    [
      adjustedArrowPositionY,
      arrowX,
      arrowY,
      borderRadius,
      variant,
      content,
      getFloatingProps,
      hideArrow,
      isTooltipHidden,
      maxWidth,
      position,
      refs.setFloating,
      staticSide,
      tooltipOffset,
      enablePopoverModalHook,
      usePopoverModalHook,
      variants,
      arrowSize,
      arrowColor
    ]
  );

  const isShowingPopover = (open || isOpenOverride) && !disabled;

  useEffect(() => {
    if (!usePopoverModalHook) return;
    if (!enablePopoverModalHook) return;
    if (!isShowingPopover) return;
    if (usePopoverModalHook.y && usePopoverModalHook.x) return;

    usePopoverModalHook.setPopover(getPopoverComponent(x, y));

    if (x && y) {
      usePopoverModalHook.setX(x);
      usePopoverModalHook.setY(y);
    }
  }, [
    usePopoverModalHook,
    enablePopoverModalHook,
    getPopoverComponent,
    isShowingPopover,
    x,
    y
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={className}
        style={style}
      >
        {children}
      </div>
      {usePopoverModalHook && enablePopoverModalHook ? null : (
        <AnimatePresence>
          {isShowingPopover && getPopoverComponent(x, y)}
        </AnimatePresence>
      )}
    </>
  );
};

export default Popover;
